import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Link, Typography, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Component } from 'react';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import UserInfo from '../UserInfo';



const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    color: theme.palette.text.secondary,
  },
  stepper: {
    backgroundColor: 'transparent',
  },
  title: {
    padding: theme.spacing(2),
  },
  link: {
    margin: theme.spacing(3),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  grid: {
    margin: `${theme.spacing(2)}px 0px`,
    padding: `${theme.spacing(2)}px 0px`,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 230,
    },
  },
  toolbar: theme.mixins.toolbar
});

function createData(title, text) {
  return { title, text };
}


class Faq extends Component {
  constructor(props) {
    super(props);

  }
  componentDidMount() {

  }

  componentWillUnmount() {

  }





  render() {
    const { classes } = this.props;

    return (


      <div className={classes.root} >
        <div className={classes.toolbar} />

        <UserInfo />

        <Grid container className={classes.grid}>

          <Grid container xs={12} sm={12} md={10} spacing={3}>

            <div className={classes.root}>


              <Typography variant="h5" component="h2" className={classes.title}>
                FAQ
              </Typography>


              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subhead">
                    What is Co-instructEd?
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="body2">
                    Co-instructEd is getting rid of the inefficient and frustrating process for learning new software tools.
                    Everyone should have a place to go to learn new software quickly, easily, and have fun while doing it!
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subhead">
                    Why should I pursue a certificate?
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="body2">
                    Companies are becoming more digital and more global - situations like the current Corona-virus show
                    the world changes quickly and so should we. By pursuing a certificate for you and your colleagues, you
                    don't just show dedication, but you can prove that you are ready for pretty much everything. Let's ride
                    the wave of digitization together!</Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subhead">
                    How do I get a certificate?
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="body2">
                    This depends on your company - the basic set of courses (Zoom and Skype) is just a way to get you
                    started. If you want to learn more software tools online with Co-instructEd, talk to your employer and
                    we will open up more courses for you. If you have fulfilled a specific set of courses, you will get your very
                    personal Co-instructEd certificate!
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subhead">
                    Does anyone see if and what questions I get wrong?
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="body2">
                    No. The questions are just our way of making sure that we don't teach you anything you already know.
                    Therefore, we personalize the content to your specific needs - because everyone might start at a
                    different level, but we all have the same goal!</Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subhead">
                    Does Co-instructEd work on every device?
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="body2">
                    Yes, Co-instructEd works on every device, as long as you are connected to the internet. We follow a
                    simple rule: learn wherever and whenever you want!
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subhead">
                    Can I watch the videos again?
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="body2">
                    Yes, the videos and course materials are available to you as long as you have an active account for the
                    course. We are glad that you are asking this question: because when we are not using software and apps
                    on a daily basis it is quite common to forget how to use it. Watch it as often as you want - we are sure
                    that you will be way better than our videos soon!</Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subhead">
                    I am stuck at one of the challenges - what should I do now?
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="body2">
                    First of all: don't worry, that happens to everyone (one of our co-founders usually gets stuck - you are
                    not alone!). Nevertheless, we have 3 possible ways to solve this:<br />
                    1) Watch the videos again and try to figure it out - all the solutions are hidden in the videos.<br />
                    2) Ask a friend of colleague who takes this course too - we want to help you collaborate and
                    sometimes asking someone in person or via email is just the best solution - we are all in this
                    together!<br />
                    3) If you take a course specific for your company then just ask your IT department - they have
                    created this course with us and are there to help.
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subhead">
                    I ran into a technical problem, what should I do?
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="body2">
                    Obstacles are a part of learning - and technical problems are part of our digital world. However, there is
                    always a solution: we recommend that you ask a friend or colleague. And if this does not help either,
                    please contact your IT department.
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subhead">
                    How can I change my password?
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="body2">
                    First, you can go back to the login-page and click "I forgot my password". If you do that, we will send you
                    an email with further instructions.<br />

                    Another option is to go to the dashboard, click on "settings" in the bottom left corner and then "change
                    password’.
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subhead">
                    What should I do with the certificate?
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="body2">
                    Certificates are made to be shown off! Once you have completed enough courses, the certificate section
                    on your dashboard will be activated and you can download and print your personal certificate. However,
                    that is just the first step: take your certificate, show it to your boss and frame it in your office or living
                    room - we should all be proud on our achievements!
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subhead">
                    I have a team challenge and my teammembers are too slow - what should I do?
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="body2">
                    lf that happens, don't be upset - you never know what might be happening on the other side -
                    nevertheless, we built some tools to help you move forward:<br />

                    To start with, you can email your teammember or call her/him and give them a friendly reminder. If you
                    don't like this idea, we have another solution: we programmed a little "reminder'-button on your
                    dashboard next to every teammember. If you click this, an auto-reminder email will be sent directly to
                    the user.
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subhead">
                    Can I watch the videos offline / without internet connection?
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="body2">
                    The short and simple answer is no. The idea of Co-instructEd is that you actively work in the native
                    software / app while you are fulfilling the courses and watch the videos. Many software tools and apps
                    are web-based and an offline version of Co-instructEd does not make sense. Please trust us on this one.</Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subhead">
                    I got the certificate - what now?
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant="body2">
                    First of all: congratulations, you are amazing! You are now officially "Co-instructEd" and can use all your
                    new skills in your everyday life. However, Co-instructEd is just a first step into a new, digital lifestyle.
                    Once you know the basics (which you now do), you can learn and get better every day by using all the
                    software tools and apps you just learned. Furthermore, if you want to learn more, just talk to your
                    company and they will give you access to more - because we never stop learning and neither do you!
                    </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>






            </div>
            <Link className={classes.link} target="_blank" rel="noopener" href={ROUTES.TOS}>Privacy Policy</Link>
            <Link className={classes.link} target="_blank" rel="noopener" href={ROUTES.LIABILITY_WAIVER}>Liability Waiver</Link>
          </Grid>



        </Grid>

      </div>

    );
  }
}

export default compose(
  withFirebase,
  withStyles(styles)
)(Faq);




