import { Button, CircularProgress, Grid, Link, Paper, TextField, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import withBrowserDetection from '../Session/withBrowserDetection';




const styles = theme => ({
  layout: {
    width: 'auto',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  toolbar: theme.mixins.toolbar
});


const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class LoginEmail extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
  componentDidMount() {

  }

  componentWillUnmount() {

  }


  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = event => {
    const { email, password } = this.state;
    this.setState({ 
      error: null,
      loading: true 
    });

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.onLoginSuccessful()
      })
      .catch(error => {
        console.log("error =>", error);
        error.message = ERROR_MSG_ACCOUNT_EXISTS;
        this.setState({ error });
        this.setState({ loading: null });
      });

    event.preventDefault();
  };


  onLoginSuccessful() {
    this.setState({ ...INITIAL_STATE });
    const { location } = this.props;
    if (location.state && location.state.from) {
      this.props.history.push(location.state.from);
    } else {
      this.props.history.push(ROUTES.DASHBOARD);
    }
  }

  render() {
    const { classes } = this.props;
    const { state } = this.props;
    const { loading } = this.state;
    const {
      email,
      password,
      error,
    } = this.state;


    const isInvalid =
      password === '' ||
      email === '';



    return (



      <main className={classes.layout}>
        <div className={classes.toolbar} />

        <Typography component="h1" variant="h4" color="textSecondary" align="center">Welcome to Co-instructEd!</Typography>

        <Paper elevation={2} className={classes.paper}>
          <React.Fragment>
            <React.Fragment>

              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <Typography variant="h6" color="textSecondary" >
                    Sign in to Co-instructEd
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    id="email"
                    name="email"
                    value={email}
                    label="Company email address"
                    onChange={this.onChange}
                    fullWidth
                    autoComplete="email"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="password"
                    name="password"
                    value={password}
                    label="Password"
                    onChange={this.onChange}
                    fullWidth
                    autoComplete="password"
                    variant="outlined"
                    type="password"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography component="h1" variant="caption" color="textSecondary">
                    <Link href={ROUTES.PASSWORD_FORGET}>Forgot password</Link>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.buttons}>
                  {loading ? null : <Button disabled={isInvalid}
                      id="btn-signon"
                      variant="contained"
                      color="primary"
                      onClick={this.onSubmit} >
                      Sign in
                    </Button>}
                    {!loading ? null : <CircularProgress size={36} style={{ marginRight: 40 }} />}
                  </div>

                </Grid>

                {error && <Grid item xs={12}>
                  <Typography variant="body1" color="error">
                    Email - Password combination not found. Please register or click forgot password if you have forgotten yours
                  </Typography>
                </Grid>}

              </Grid>
            </React.Fragment>

          </React.Fragment>

        </Paper>

        <Paper elevation={2} className={classes.paper}>
          <React.Fragment>
            <React.Fragment>
              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <Typography variant="h6" color="textSecondary"  >
                    First time?
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.buttons}>
                    <Button variant="outlined" color="primary" href={ROUTES.REGISTER}>
                      Register here
                    </Button>
                  </div>
                </Grid>

              </Grid>
            </React.Fragment>
          </React.Fragment>
        </Paper>

      </main>

    );
  }
}

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/user-not-found';
const ERROR_MSG_ACCOUNT_EXISTS = 'Email - Password combination not found. Please register or click forgot password if you have forgotten yours';

export default compose(
  withFirebase,
  withStyles(styles),
  withBrowserDetection
)(LoginEmail);
