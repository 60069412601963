import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';

import DhProgressTable from './DhProgressTable';
import { CssBaseline, Grid, Paper, withStyles, Drawer, List, ListItemIcon, ListItemText, Divider, ListItem, Typography, Button, Hidden } from '@material-ui/core';
import Navigation from '../Navigation';
import UserInfo from '../UserInfo';
import CertificateTable from './CertificateTable';
import withBrowserDetection from '../Session/withBrowserDetection';
import DhProgressPanel from './DhProgressPanel';
import ReactJoyride from 'react-joyride';
import Cookies from 'universal-cookie';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    margin: `${theme.spacing(2)}px 0px`,
    padding: `0px`,
    [theme.breakpoints.up('sm')]: {
      margin: `0px 0px`,
      padding: `${theme.spacing(2)}px`,
      paddingLeft: `${200+theme.spacing(2)}px`,
    },
  },
  button: {
    marginTop: theme.spacing(1)
  },
  toolbar: theme.mixins.toolbar
});

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.onStartNextVideo = this.onStartNextVideo.bind(this);

    this.state = {
      loading: true,
      run: false,
      steps: [
        {
          target: '#step1',
          content: 'You can start your next course here',
          disableBeacon: true,
          placement: 'auto',
        },
        {
          target: '#step1',
          content: 'You can expand the courses and see the course materials here',
          disableBeacon: true,
          placement: 'auto',
        },
        {
          target: '#team',
          content: 'Here you can see your team and their progress.',
          disableBeacon: true,
          placement: 'auto',
        },
        {
          target: '#dashboardMobile',
          content: 'Click here and go back to your dashboard',
          disableBeacon: true,
          placement: 'auto',
        },
        {
          target: '#dashboard',
          content: 'Click here and go back to your dashboard',
          disableBeacon: true,
          placement: 'auto',
        }
      ]
    };
  }

  onStartNextVideo() {
    // var index = this.state.selected
    // var option = this.props.question.options[index]
    // if (option) {
    //   this.setState({ selected: "" });
    //   this.props.onQuestionSelected(option);
    // }
  }

  componentDidMount() {
    this.loadData()
  }

  componentWillUnmount() {

  }


  loadData = () => {
    const { authUser } = this.props;

    console.log("loadData => ", authUser);
    this.setState({ userId: authUser.uid });

    this.props.firebase
        .groupByUserId(authUser.uid)
        .onSnapshot(snapshot => {
          console.log("snapshot = ", snapshot);
          if(snapshot.docs && snapshot.docs[0]){
            var group = snapshot.docs[0].data() ? snapshot.docs[0].data() : null
            if (group) {
              const index = group.userIds.indexOf(authUser.uid);
              group.userIds.splice(index, 1);
              this.setState({
                userIds: group.userIds.length == 0 ? null : group.userIds,
                loaded: true
              });
              var cookies = new Cookies();
              setTimeout(function() { //Start the timer
                this.setState({run: cookies.get('tutorialDone') == null}) //After 1 second, set render to true
              }.bind(this), 2000)
            }
          }
        }, function (error) {
          console.log("loadData error = ", error);
        })

  }

  handleJoyrideCallback = data => {
    const { action, index, status, type } = data;
    const { lastTourIdx } = this.state;
    if(action == 'update' && status == 'running' && index != lastTourIdx){
      this.setState({
        expandFirstItem: index == 1,
        lastTourIdx: index,
        run: false
      });
      setTimeout(function() { //Start the timer
        this.setState({run: true}) //After 1 second, set render to true
      }.bind(this), 500)
    }
    if(status == 'finished' || action == 'skip'){
      var cookies = new Cookies();
      cookies.set('tutorialDone', 'true', { path: '/' });
      console.log("set Cookie"); 
      this.setState({
        expandFirstItem: false,
        run: false
      });
    }

    console.log("handleJoyrideCallback",action, index, status, type); 
  };


  render() {
    const { classes } = this.props;
    const { userId } = this.state;
    const { userIds } = this.state;
    const { loaded } = this.state;
    const { error } = this.state;
    const { run } = this.state;
    const { steps } = this.state;
    const { expandFirstItem } = this.state;


    console.log("userId = ", userId);
    console.log("userIds = ", userIds);
    console.log("loaded = ", loaded);
    console.log("error = ", error);
    console.log("run = ", run);
    console.log("expandFirstItem = ", expandFirstItem);

    return (
      <div className={classes.root} >
        <div className={classes.toolbar} />

        {loaded && <ReactJoyride
          run={run}
          steps={steps}
          continuous
          debug={true}
          showSkipButton={true}
          locale={{ back: 'Back', close: 'Close', last: 'Done', next: 'Next', skip: 'Skip' }}
          callback={this.handleJoyrideCallback}
          styles={{
            options: {
              // arrowColor: '#e3ffeb',
              // backgroundColor: '#e3ffeb',
              // overlayColor: 'rgba(79, 26, 0, 0.4)',
              primaryColor: '#12254c',
              // textColor: '#004a14',
              width: 400,
              zIndex: 10000,
            }
          }}
        />}

        <UserInfo />

          <Grid container className={classes.grid} xs={12} sm={12} md={12} spacing={3} >

            <Grid item xs={12} sm={12} md={12} lg={8} >
              {loaded &&
                <div >
                  <DhProgressPanel
                    expandFirstItem={expandFirstItem}
                    userId={userId}
                  />
                </div>
              }
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4} >
              {loaded &&
                  <CertificateTable
                    userId={userId}
                  />
              }
            </Grid>

            <Hidden xsDown implementation="css">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{padding: 10, marginTop: 24}}>
                  {loaded &&
                    <a href="mailto:office@co-instructed.com"><img src="/co-instructed_48hours_desktop.png" width="100%" /> </a>
                  }
                </Grid>
              </Hidden>


            {loaded && <Grid item xs={12} sm={12} md={12}>
              <Typography style={{marginTop: 12}} variant="h6">Your team</Typography>
            </Grid>}
            

            {!userIds && loaded && <Grid id={'team'} item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Paper elevation={2} style={{padding: 16}} >
            <Typography variant="caption">Looking for teammembers<br/>
            Soon your teammembers will appear in this area. But you don't have to wait for them. You can start your first course now.
              </Typography>
              </Paper>
              </Grid>
            }



            {userIds && loaded && userIds.map(otherId => (
              <Grid id={'team'} item xs={12} sm={12} md={6} lg={4} xl={4}>
                {loaded &&
                  <DhProgressTable
                    userId={otherId} 
                    currUserId={userId}
                    isOther={true}
                    />
                }
              </Grid>
            ))}


              
              <Hidden smUp implementation="css">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{padding: 16, marginTop: 16}}>
                  {loaded &&
                    <a href="mailto:office@co-instructed.com"><img src="/co-instructed_48hours_mobile.png" width="100%" href="mailto:office@co-instructed.com" /></a>
                  }
                </Grid>
              </Hidden>

          </Grid>
      </div>

    );
  }
}



const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withBrowserDetection,
  withAuthorization(condition),
  withStyles(styles)
)(DashboardPage);
