import { Button, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import withBrowserDetection from '../Session/withBrowserDetection';



const styles = theme => ({
  layout: {
    width: 'auto',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: '600',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  toolbar: theme.mixins.toolbar
});



class Start extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    const { authUser } = this.props;
    if(authUser){
      this.props.history.push(ROUTES.DASHBOARD);
    }else{
      this.props.history.push(ROUTES.LOGIN);
    }

  }

  componentWillUnmount() {

  }


  onClickLogin = event => {
    this.props.history.push(ROUTES.LOGIN);
  };
  onClickRegister = event => {
    this.props.history.push(ROUTES.REGISTER);
  };



  render() {
    const { classes } = this.props;
    const { state } = this.props;


    return (



      <main className={classes.layout}>
        <div className={classes.toolbar} />


        <Paper elevation={2} className={classes.paper}>
          <React.Fragment>
            <React.Fragment>

              <Grid container spacing={3}>
                

                <Typography component="h1" variant="h4" color="textSecondary" align="center">Sind Sie zum ersten Mal bei Co-instructEd?</Typography>




                <Grid
                  container
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  spacing={5}
                  style={{ margin: 20 }}
                >

                  <Grid item xs container direction="column" alignItems="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.onClickRegister}
                      className={classes.button}
                    >
                      Ja<br />-> zur Registrierung
                    </Button>

                  </Grid>

                  <Grid item xs container direction="column" alignItems="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.onClickLogin}
                      className={classes.button}
                    >
                      Nein<br />-> zum Login
                    </Button>

                  </Grid>
                  

                </Grid>

              </Grid>
            </React.Fragment>

          </React.Fragment>

        </Paper>

      </main>

    );
  }
}


export default compose(
  withFirebase,
  withStyles(styles),
  withBrowserDetection
)(Start);
