import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';

import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';

import { CssBaseline, Grid, Paper, withStyles, Drawer, List, ListItemIcon, ListItemText, Divider, ListItem, Typography, Button, Tabs, Tab, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import withBrowserDetection from '../Session/withBrowserDetection';
import DhProgressTable from '../Dashboard/DhProgressTable';
import withAdmin from '../Session/withAdmin';
import moment from 'moment';
import AdminTabs from './AdminTabs';
import { CSVLink } from "react-csv";
import { DatePicker } from "@material-ui/pickers";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    margin: `${theme.spacing(2)}px 0px`,
    padding: `${theme.spacing(2)}px`,
  },
  button: {
    marginTop: 0,
  },
  toolbar: theme.mixins.toolbar,
  table: {
    padding: 0,
    margin: "0px"
  },
  head: {
    backgroundColor: theme.palette.secondary.main,
  },
  paper: {
    margin: theme.spacing(1),
    padding: "0px"
  },
});

class AdminBilling extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dataCalculated: false,
    };
  }



  componentDidMount() {
    this.setSelectedDate(new Date())
  }

  componentWillUnmount() {

  }

  handleDateChange = date => {
    this.setSelectedDate(date);
  };

  setSelectedDate = (selectedDate) => {
    console.log("setSelectedDate", selectedDate);
    const yearMonth = moment(selectedDate).format('YYYY-MM');
    this.setState({
      selectedDate: selectedDate,
      yearMonth: yearMonth
    });
    this.loadData(yearMonth)
  }

  loadData = (date) => {
    const { firebase } = this.props;
    this.setState({
      dataCalculated: false,
      billingInfo: null,
      countAll: null,
      createdAt: null,
      csvData: null,
    });
    firebase.
      billingResults(date)
      .onSnapshot(snapshot => {
        const item = snapshot.exists ? snapshot.data() : null
        console.log("billingResultsObject", item);

        if(item != null && item.courses != null){

          const csvData = item.courses.map((course, idx) => (
            {
              name: course.name,
              count: course.count,
              subscribers: course.subscribers,
            }
          ))

          this.setState({
            billingInfo: JSON.stringify(item, undefined, 2),
            countAll: item.count,
            createdAt: item.createdAt,
            csvData: csvData,
            dataCalculated: true
          });
        } else{
          
        }
      })
  }

  calcBilling = () => {
    const { firebase } = this.props;
    const { yearMonth } = this.state;
    firebase.
      updateCalculateBilling(yearMonth)
      .then(item => {
        console.log("calcBilling done", item);
      })
      .catch(error => {
        console.log("users load", error);
      })
  }



  render() {
    const { classes } = this.props;
    const { billingInfo } = this.state;
    const { countAll } = this.state;
    const { csvData } = this.state;
    const { selectedDate } = this.state;
    const { yearMonth } = this.state;
    const { createdAt } = this.state;
    const { dataCalculated } = this.state;

    console.log("billingResultsObject", billingInfo);


    return (
      <div className={classes.root} >
        <div className={classes.toolbar} />

          <AdminTabs />

          <Grid container className={classes.grid} xs={12} sm={12} md={10} lg={8} spacing={3}>

            <Grid item xs="auto">
              <DatePicker
                variant="inline"
                openTo="month"
                views={["year", "month"]}
                label="Abrechnungsmonat"
                format="YYYY-MM"
                value={selectedDate}
                onChange={this.handleDateChange}
              />
            </Grid>

            <Grid item xs="auto">
              <Button variant="contained" color="primary"  onClick={event => this.calcBilling()}>
                Jetzt neu berechnen
              </Button>
            </Grid>

              {countAll &&
                <Grid item xs="auto">
                 <Typography variant="body2">
                    Nutzer insgesamt: {countAll}
                </Typography>
                </Grid>
              }

              {createdAt && 
              <Grid item xs="auto">
                <Typography variant="caption" className={classes.title}>
                  Zuletzt berechnet am: {moment(createdAt.toDate()).format('DD-MM-YYYY, HH:mm:ss')}
                </Typography>
                </Grid>
              }

             

              {csvData && 
              <Grid item xs={12}>
                <Paper elevation={2} className={classes.paper}>

                <Table className={classes.table} size="small">
                  <TableHead className={classes.head}>
                    <TableRow>
                      <TableCell><Typography >Course</Typography></TableCell>
                      <TableCell ><Typography >Nutzer</Typography></TableCell>
                      <TableCell align="right"><Typography >CSV</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {csvData && csvData.map((course, idx) => (
                      <TableRow>
                        <TableCell size="small" component="th" scope="row" padding="default" >
                          <Typography >
                            {course.name}
                          </Typography>
                        </TableCell>
                        <TableCell size="small">
                          {course.count}
                        </TableCell>

                        <TableCell size="small" align="right">
                        {course && course.subscribers != null && course.subscribers.length != 0 && <Button variant="outlined" className={classes.button}>
                          <CSVLink 
                            filename={yearMonth+"-"+course.name+".csv"}
                            separator={";"}
                            data={course.subscribers}>
                                Download
                            </CSVLink>
                            </Button>
                        }
                        </TableCell>
                      </TableRow>

                    ))}
                  </TableBody>
                </Table>
                </Paper>
              </Grid>
              }

              <Grid item xs={12}>
                <Typography variant="body2" className={classes.title}>
                <div><pre>{billingInfo}</pre></div>
                </Typography>
              </Grid>
            </Grid>
          

      </div>

    );
  }
}



const condition = authUser => !!authUser;

export default compose(
  withStyles(styles),
  withFirebase,
  withAuthorization(condition),
  withAdmin,
)(AdminBilling);