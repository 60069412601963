import { Button, FormControl, FormHelperText, Grid, Link, Paper, Snackbar, SnackbarContent, TextField, Typography, withStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { Component } from 'react';
import Avatar from 'react-avatar-edit';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthentication, withEmailVerification } from '../Session';
import UserInfo from '../UserInfo';



const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    margin: `${theme.spacing(2)}px 0px`,
    padding: `${theme.spacing(2)}px`,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: `${200 + theme.spacing(2)}px`,
    },
  },
  gridInner: {
    padding: `${theme.spacing(2)}px`,
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  paper: {
    margin: 0,
    padding: 0
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  snackbar: {
    backgroundColor: theme.palette.secondary.main
  },
  snackbarMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  snackbarIcon: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
});


class Settings extends Component {
  constructor(props) {
    super(props);

    const { authUser } = this.props;


    this.state = {
      preview: null,
      src: null,
      key: "empty",
      name: authUser.name,
      surname: authUser.surname,
      pwdChangeSuccess: false,
      passwordOld: '',
      passwordNew: '',
      passwordNew2: '',
    }
    this.onSaveData = this.onSaveData.bind(this)
    this.onChangePassword = this.onChangePassword.bind(this)
    this.onChange = this.onChange.bind(this)

    this.onCrop = this.onCrop.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this)
    this.handlePasswordSnackbarClose = this.handlePasswordSnackbarClose.bind(this)
    this.handleProfileSnackbarClose = this.handleProfileSnackbarClose.bind(this)
    // this.openDeleteAccountDialog = this.openDeleteAccountDialog.bind(this)
    // this.closeDeleteAccountDialog = this.closeDeleteAccountDialog.bind(this)
    // this.onDeleteAccountClicked = this.onDeleteAccountClicked.bind(this)
  }
  componentDidMount() {

    this.setState({ showPasswordChange: this.isSignInProviderPassword() })


    var firebase = this.props.firebase
    const { authUser } = this.props;
    var user = authUser;
    console.log("providerData uid: " + user.uid);
    console.log("providerData displayName: " + user.displayName);
    if(user && user.providerData){
      user.providerData.forEach(function (profile) {
        console.log("providerData Sign-in provider: " + profile.providerId);
        console.log("providerData Provider-specific UID: " + profile.uid);
        console.log("providerData Name: " + profile.displayName);
        console.log("providerData Email: " + profile.email);
        console.log("providerData Photo URL: " + profile.photoURL);
      });
    }
    

  }

  componentWillUnmount() {

  }

  isSignInProviderPassword(){
    const { authUser } = this.props;
    var user = authUser;
    var isPassword = false
    if(user && user.providerData){
      user.providerData.forEach(function (profile) {
        if(profile.providerId == "password"){
          isPassword = true
        }
      });
    }
    return isPassword
  }


  onSaveData() {
    const { name, surname } = this.state;
    const { authUser } = this.props;

    this.props.firebase.user(authUser.uid).set(
      {
        name: name,
        surname: surname
      },
      { merge: true },
    ).then(() =>{
      this.setState({
        profileChangeSuccess: true,
      })
    });
  }


  onChangePassword() {
    const { passwordOld, passwordNew, passwordNew2 } = this.state;

    this.props.firebase.doReauthenticate(passwordOld).then(() => {
      console.log("reauthenticate ok!");
      this.props.firebase.doPasswordUpdate(passwordNew).then(() => {
        console.log("Password updated!");
        this.setState({
          pwdChangeSuccess: true,
          passwordOld: '',
          passwordNew: '',
          passwordNew2: '',
        })
      }).catch((error) => { console.log(error); });
    }).catch((error) => { 
      console.log(error); 
      this.setState({ passwordErrorMsg: error.message })
    });

  }

  handlePasswordSnackbarClose = () => {
    this.setState({ pwdChangeSuccess: false });
  };
  handleProfileSnackbarClose = () => {
    this.setState({ profileChangeSuccess: false });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };



  onClose() {
    this.setState({ preview: null })
  }

  onCrop(preview) {
    this.setState({ preview })
  }

  onBeforeFileLoad(elem) {
    if (elem.target.files[0].size > 5000000) {
      alert("File is too big!");
      elem.target.value = "";
    };
  }



  onUploadImage = event => {
    const { preview } = this.state;
    const { authUser } = this.props;

    var firebase = this.props.firebase
    var storageRef = firebase.storage.ref()

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef.child('profile/' + authUser.uid + '.jpg').putString(preview, 'data_url');

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(firebase.storageTaskEvent.STATE_CHANGED, // or 'state_changed'
      function (snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case firebase.storageTaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storageTaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      }, function (error) {

        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        console.log('error uploading', error);
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;

          case 'storage/canceled':
            // User canceled the upload
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      }, () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.onClose()
          this.setState({ key: downloadURL })
          console.log('File available at', downloadURL);
          firebase.user(authUser.uid).set(
            { picture: downloadURL },
            { merge: true },
          );
        });
      });

    event.preventDefault();
  };


  openDeleteAccountDialog = () => {
    this.setState({ showDeleteAccount: true });
  };
  closeDeleteAccountDialog = () => {
    this.setState({ showDeleteAccount: false });
  };
  onDeleteAccountClicked() {
    const { deletePassword } = this.state;
    var firebase = this.props.firebase
    var user = firebase.auth.currentUser;


    this.props.firebase.doReauthenticate(deletePassword).then(() => {
      console.log("reauthenticate ok!");

      firebase.usersDeletedAdd(user)
      .then(() => {
        console.log("userDeletes added");
        user.delete().then(function() {
          console.log("user deleted");
          this.closeDeleteAccountDialog()
        }).catch(function(error) {
          console.log("user delete error", error);
        });
      }).catch((error) => { 
        console.log("userDeletes error", error);
      });

    }).catch((error) => { 
      console.log(error); 
      this.setState({ deleteErrorMsg: "password falsch" })
    });



    
  };


  render() {
    const { classes } = this.props;
    const { state } = this.state;
    const { preview } = this.state;
    const { key } = this.state;
    const { error } = this.state;
    const { passwordErrorMsg } = this.state;
    const { showPasswordChange } = this.state;
    const { pwdChangeSuccess } = this.state;
    const { profileChangeSuccess } = this.state;
    const { authUser } = this.props;
    const {
      passwordOld,
      passwordNew,
      passwordNew2,
      name,
      surname,
    } = this.state;


    const isInvalidPassword =
      passwordOld === '' ||
      passwordNew === '' ||
      passwordNew !== passwordNew2;

    const isInvalidData =
      name === '' ||
      surname === '';

    const showNotSamePasswordError = passwordNew !== passwordNew2 && passwordNew2 !== ''
    const isPasswordValid = passwordNew.length >= 6 || passwordNew == ''


    return (
      <div className={classes.root} >
        <div className={classes.toolbar} />

        <UserInfo />

        <Grid container className={classes.grid} xs={12} sm={12} md={12} spacing={2} >

          <Grid item xs={12} sm={12} md={8} lg={6}>


            <Paper elevation={2} className={classes.paper}>
              <Grid container spacing={2} className={classes.gridInner}>
                <Grid item xs={12}>
                  <Typography variant="h5" className={classes.header}>
                    Personal data
                        </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    value={name}
                    onChange={this.onChange}
                    fullWidth
                    variant="outlined"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="surname"
                    name="surname"
                    label="Surname"
                    value={surname}
                    onChange={this.onChange}
                    fullWidth
                    variant="outlined"
                    type="text"
                  />
                </Grid>
              </Grid>
              <div className={classes.buttons}>

                <Button disabled={isInvalidData}
                  id="btn-signon"
                  variant="contained"
                  color="primary"
                  onClick={this.onSaveData}
                  className={classes.button}
                >
                  Save
                    </Button>
              </div>

            </Paper>

          </Grid>


          {showPasswordChange && <Grid item xs={12} sm={12} md={8} lg={6}>

            <Paper elevation={2} className={classes.paper}>
              <Grid container spacing={2} className={classes.gridInner}>
                <Grid item xs={12}>
                  <Typography variant="h5" className={classes.header}>
                    Change password
                        </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="passwordOld"
                    name="passwordOld"
                    value={passwordOld}
                    label="Old password"
                    onChange={this.onChange}
                    fullWidth
                    variant="outlined"
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    error={!isPasswordValid}
                  >
                    <TextField
                      required
                      error={!isPasswordValid}
                      id="passwordNew"
                      name="passwordNew"
                      value={passwordNew}
                      label="New password"
                      onChange={this.onChange}
                      fullWidth
                      variant="outlined"
                      type="password"
                    />
                    {isPasswordValid ? null : <FormHelperText id="component-error-text">Please choose a password with at least 6 characters</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>

                <FormControl
                    fullWidth
                    error={showNotSamePasswordError}
                  >
                  <TextField
                    required
                    error={showNotSamePasswordError}
                    id="passwordNew2"
                    name="passwordNew2"
                    value={passwordNew2}
                    onChange={this.onChange}
                    label="Repeat new password"
                    fullWidth
                    variant="outlined"
                    type="password"
                  />
                    {showNotSamePasswordError ? <FormHelperText id="component-error-text">Oops! Please retype the password. The passwords must match.</FormHelperText> : null}
                  </FormControl>

                </Grid>
              </Grid>

              {passwordErrorMsg && <Typography variant="body2" color='primary' style={{ marginLeft: 20 }} className={classes.header}>
                    Wrong old password
                        </Typography>}

              <div className={classes.buttons}>

                <Button disabled={isInvalidPassword || !isPasswordValid}
                  id="btn-signon"
                  variant="contained"
                  color="primary"
                  onClick={this.onChangePassword}
                  className={classes.button}
                >
                  Save
                    </Button>
              </div>
            </Paper>

            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}

              open={pwdChangeSuccess}
              autoHideDuration={6000}
              onClose={this.handlePasswordSnackbarClose}
            >
              <SnackbarContent
                className={classes.snackbar}
                message={
                  <span className={classes.snackbarMessage}>
                    <CheckCircleIcon className={classes.snackbarIcon} />
                    Password successfully changed!
                  </span>
                } />
            </Snackbar>
          </Grid>}


          <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}

              open={profileChangeSuccess}
              autoHideDuration={6000}
              onClose={this.handleProfileSnackbarClose}
            >
              <SnackbarContent
                className={classes.snackbar}
                message={
                  <span className={classes.snackbarMessage}>
                    <CheckCircleIcon className={classes.snackbarIcon} />
                    Personal data successfully changed!
                  </span>
                } />
            </Snackbar>

          <Grid item xs={12} sm={12} md={8} lg={6}>

            <Paper elevation={2} className={classes.paper}>
              <Grid container spacing={2} className={classes.gridInner}>
                <Grid item xs={12}>
                  <Typography variant="h5" className={classes.header}>
                    Change profile picture
                        </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Avatar
                    key={key}
                    height={220}
                    width={300}
                    imageWidth={300}
                    labelStyle={{
                      fontSize: '1em',
                      fontWeight: '300',
                      display: 'inline-block',
                      fontFamily: 'sans-serif',
                      color: 'primary',
                      cursor: 'pointer'
                    }}
                    borderStyle={{
                      border: '2px solid #979797',
                      borderStyle: 'dashed',
                      borderRadius: '8px',
                      textAlign: 'center'
                    }}
                    onCrop={this.onCrop}
                    onClose={this.onClose}
                    onBeforeFileLoad={this.onBeforeFileLoad}
                    label="Select new picture"
                  />
                </Grid>
              </Grid>

              <div className={classes.buttons}>

                <Button
                  id="btn-upload"
                  variant="contained"
                  color="primary"
                  onClick={this.onUploadImage}
                  className={classes.button}
                >
                  Save picture
                  </Button>
              </div>
            </Paper>

          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="body2" className={classes.button}>
              If you want to delete your account, click <Link  onClick={event => this.openDeleteAccountDialog()}>here</Link>.
            </Typography>
          </Grid>
        </Grid>



        <Dialog
          open={this.state.showDeleteAccount}
          onClose={this.closeDeleteAccount}
          maxWidth="xs"
          aria-labelledby="dialog-delete-account"
          >
          <DialogTitle id="dialog-delete-account">Delete user?</DialogTitle>
          <DialogContent>
            <Typography style={{ margin: 16 }} className={classes.typography}>
            Are you sure that you want to delete your account? Your current progress will be deleted and can't be restored. Furthermore, you will lose access to all videos and course materials immediately. Are you sure that you want to delete your account and your progress?
          </Typography>
          <TextField
            required
            id="deletePassword"
            name="deletePassword"
            value={this.state.deletePassword}
            label="Aktuelles password"
            onChange={this.onChange}
            fullWidth
            variant="outlined"
            type="password"
          />
          {!this.state.deleteErrorMsg ? null : <FormHelperText id="component-error-text">Wrong password</FormHelperText>}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={event => this.closeDeleteAccountDialog()}
              color="default">
              Cancel
          </Button>
            <Button
              onClick={event => this.onDeleteAccountClicked()}
              color="primary">
              Delete
          </Button>
          </DialogActions>
        </Dialog>



      </div>

    );


  }
}


export default compose(
  withFirebase,
  withEmailVerification,
  withAuthentication,
  withStyles(styles)
)(Settings);
