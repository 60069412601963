import { FormControl, FormControlLabel, Radio, RadioGroup, Card, CardActionArea, CardContent, Paper, Button, Checkbox, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';



const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  group: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    display: "block",
  },

});


class Exercise extends Component {
  constructor(props) {
    super(props);
    this.saveCheckedState = this.saveCheckedState.bind(this);
    this.onStartDoneClicked = this.onStartDoneClicked.bind(this);
    this.onSingleDoneClicked = this.onSingleDoneClicked.bind(this);

    this.state = {
      loading: true,
    };

  }

  saveCheckedState() {
    const { group } = this.props;
    const { courseId } = this.props;
    const { authUser } = this.props;
    const { firebase } = this.props;
    const { checkStates } = this.state;

    var exercise = {}
    Object.keys(checkStates).forEach(function (key) {
      var value = checkStates[key];
      exercise[key] = value ? firebase.fieldValue.arrayUnion(authUser.uid) : firebase.fieldValue.arrayRemove(authUser.uid)
    })

    this.props.firebase
      .groupCourseExerciseProgress(group.id, courseId)
      .set(
        { exercise: exercise },
        { merge: true },
      )
      .then(() => {
        this.props.history.push(ROUTES.DASHBOARD);
      })
      .catch(error => {
        console.log("error = ", error);
      });

    this.props.history.push(ROUTES.DASHBOARD);

  }

  onStartDoneClicked() {
    const { group } = this.props;
    const { courseId } = this.props;
    const { authUser } = this.props;
    const { firebase } = this.props;

    var exercise = {}
    exercise[authUser.uid] = firebase.fieldValue.arrayUnion(authUser.uid)

    firebase
      .groupCourseExerciseProgress(group.id, courseId)
      .set(
        { exercise: exercise },
        { merge: true },
      )
      .catch(error => {
        console.log("error = ", error);
      });
  }

  onSingleDoneClicked() {
    this.props.history.push(ROUTES.DASHBOARD);
  }

  onStartNextCourseClicked = () => {
    var nextCourse = this.getNextCourse()
    if(nextCourse){
      this.onCourseClicked(nextCourse)
    }
  }
  getNextCourse(){
    const { courses } = this.state;
    console.log("getNextCourse = ", courses);
    var next = null
    if(courses){
      courses.forEach((course, idx) => {
        var videosDone = this.videosDoneItem(course.id)
        console.log("getNextCourse videodone = ", videosDone);
        if (videosDone && videosDone.length == course.videoCount) {
        } else {
          next = course
          return
        }
      })
    }
    return next
  }
  videosDoneItem(courseId){
    const { progress } = this.props;
    console.log("videosDoneItem = ", progress, courseId);
    if (process && progress[courseId] && progress[courseId].videosDone) {
      return progress[courseId].videosDone
    }
    return null
  }

  urlNameOrCourseId(course){
    return course.urlName ? course.urlName : course.id
  }

  onCourseClicked(course) {
    this.props.history.push(ROUTES.COURSE + "/" + this.urlNameOrCourseId(course))
    window.location.reload()
  }


  componentDidMount() {
    const { authUser } = this.props;
    const { exercise } = this.props;
    const { group } = this.props;
    const { groupProgress } = this.props;

    var otherUsers = group.userIds.slice(0);
    const index = otherUsers.indexOf(authUser.uid);
    otherUsers.splice(index, 1);

    var checkStates = {}
    for (var i = 0; i < otherUsers.length; i++) {
      var id = otherUsers[i]
      checkStates[id] = (groupProgress && groupProgress.exercise && groupProgress.exercise[id] && groupProgress.exercise[id].includes(authUser.uid)) == true

      this.props.firebase
        .user(id)
        .get()
        .then(snapshot => {
          const user = snapshot.exists ? snapshot.data() : {}
          if (user) {
            user.id = snapshot.id
            var userData = this.state.userData ? this.state.userData : {}
            userData[user.id] = user
            this.setState({ userData: userData });

          }

        }, function (error) {
          console.log("error = ", error);
        });
    }

    checkStates[authUser.uid] = (groupProgress && groupProgress.exercise && groupProgress.exercise[authUser.uid] && groupProgress.exercise[authUser.uid].includes(authUser.uid)) == true


    this.setState({ checkStates: checkStates });
    this.setState({ otherUsers: otherUsers });


    this.props.firebase
      .coursesList(authUser.videoGroup)
      .then(courses => {
        console.log("coursesList load done", courses);
        this.setState({ courses: courses });
      }, function (error) {
        console.log("coursesList load", error);
      });

  }

  componentWillUnmount() {

  }


  handleChange = name => event => {
    const { checkStates } = this.state;
    checkStates[name] = event.target.checked
    this.setState({ checkStates: checkStates });
  };


  onSingleStateChange = name => event => {
    const { checkStates } = this.state;
    var checked = event.target.checked
    checkStates[name] = checked
    this.setState({ checkStates: checkStates });

    const { group } = this.props;
    const { courseId } = this.props;
    const { authUser } = this.props;
    const { firebase } = this.props;

    var exercise = {}
    exercise[authUser.uid] = checked ? firebase.fieldValue.arrayUnion(authUser.uid) : firebase.fieldValue.arrayRemove(authUser.uid)

    this.props.firebase
      .groupCourseExerciseProgress(group.id, courseId)
      .set(
        { exercise: exercise },
        { merge: true },
      )
      .catch(error => {
        console.log("error = ", error);
      });


  };

  isFirstUser() {
    const { exercise } = this.props;
    return exercise && exercise.id == "first"
  }

  isFirstUserStartDone() {
    const { groupProgress } = this.props;
    const { authUser } = this.props;
    return authUser && groupProgress && groupProgress.exercise && groupProgress.exercise[authUser.uid] && groupProgress.exercise[authUser.uid].includes(authUser.uid)
  }

  render() {
    const { classes } = this.props;
    const { exercise } = this.props;
    const { authUser } = this.props;
    const { group } = this.props;
    const { groupProgress } = this.props;
    const { checkStates } = this.state;
    const { otherUsers } = this.state;
    const { userData } = this.state;
    const { userDone } = this.state;
    const { courses } = this.state;

    var hasNextCourse = this.getNextCourse()


    console.log("exercise => ", exercise);
    console.log("group => ", group);
    console.log("groupProgress => ", groupProgress);


    console.log("checkStates => ", checkStates);
    console.log("otherUsers => ", otherUsers);
    console.log("userData => ", userData);
    console.log("userDone => ", userDone);
    console.log("courses => ", courses);
    console.log("hasNextCourse => ", hasNextCourse);

    console.log("isFirstUser => ", this.isFirstUser());
    console.log("isFirstUserStartDone => ", this.isFirstUserStartDone());

    if (exercise.id == "single") {
      return (
        <div >
          <Paper elevation={2} className={classes.paper}>

            <Typography variant="h6" component="h2" className={classes.title}>
              {exercise.title}
            </Typography>
            


            {exercise.body &&
              <Grid item className={classes.title}>
                <div dangerouslySetInnerHTML={{__html: exercise.body.replace(/{email}/g, authUser.email).replace(/{name}/g, authUser.name).replace(/{surname}/g, authUser.surname)}} />
              </Grid>
            }

            {/* {exercise.body &&
              <Typography variant="caption" component="h2" className={classes.title}>
                Thank you! Good job! We are currently working hard on analyzing if you successfully finished the challenge - once we confirmed, you will get a confirmation that you successfully passed this course.
              </Typography>
            } */}


          </Paper>

          <Grid
            container
            direction="row"
            alignItems="center"
            spacing="8"
            >

            {hasNextCourse && <Grid item >
              <Button variant="contained" color="primary" className={classes.button} onClick={(param) =>
                this.onStartNextCourseClicked()}>
                Start next Course
              </Button>
            </Grid>}
            <Grid item >
              <Button size="small"  variant="caption" color="secondary" className={classes.button} onClick={(param) =>
                this.onSingleDoneClicked()}>
                {exercise.action}
              </Button>
            </Grid>
          </Grid>
        </div>
      );
    }

    if (this.isFirstUser() && !this.isFirstUserStartDone()) {
      return (
        <div >
          <Paper elevation={2} className={classes.paper}>

            <Typography variant="h6" component="h2" className={classes.title}>
              {exercise.start.title}
            </Typography>

            <Typography variant="body2" component="h2" className={classes.title}>
              {exercise.start.instructions && exercise.start.instructions.map((instruction, index) => (
                <p>{index + 1}. {instruction}</p>
              ))}
            </Typography>

          </Paper>

          <Button variant="contained" color="primary" className={classes.button} onClick={(param) =>
            this.onStartDoneClicked()}>
            {exercise.start.action}
          </Button>

        </div>
      );
    } else {
      return (
        <div >
          <Paper elevation={2} className={classes.paper}>

            <Typography variant="h6" component="h2" className={classes.title}>
              Challenge: {exercise.title}
            </Typography>

            <Typography variant="body2" component="h2" className={classes.title}>
              {exercise.instructions && exercise.instructions.map((instruction, index) => (
                <p>{index + 1}. {instruction}</p>
              ))}
            </Typography>

            <Typography variant="body2" component="h2" color="primary" className={classes.title}>
              Bitte bestätigen Sie, dass Sie und Ihre Teammitglieder folgende Exercisen erfüllt haben:
            </Typography>

            {otherUsers && otherUsers.map(otherId => {
              var name = userData && userData[otherId] && userData[otherId].name
              if (otherId == groupProgress.firstUserId && !this.isFirstUser() && exercise.taskFirst) {
                var label = exercise.taskFirst.replace(/{firstName}/g, name);
              } else if(exercise.taskOther){
                var label = exercise.taskOther.replace(/{firstName}/g, name);
              }

              return (<FormControlLabel className={classes.group}
                control={
                  <Checkbox checked={checkStates[otherId]} onChange={this.handleChange(otherId)} value={otherId} color="primary" />
                }
                label={label}
              />)
            })}

          </Paper>

          <Button variant="contained" color="primary" className={classes.button} onClick={(param) =>
            this.saveCheckedState()}>
            Save
          </Button>

        </div>
      );
    }

  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
  withStyles(styles)
)(Exercise);
