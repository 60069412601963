import { FormControl, FormControlLabel, Radio, RadioGroup, Card, CardActionArea, CardContent, Paper, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { compose } from 'recompose';
import Plyr from 'react-plyr';
import Player from './Player';



const styles = theme => ({
  paper: {
    padding: theme.spacing(2)
  },
  paperPlayer: {

  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },

});


class Explanation extends Component {
  constructor(props) {
    super(props);
    this.onClickContinue = this.onClickContinue.bind(this);

    this.state = {
      loading: true,
    };

  }

  onClickContinue() {
    const { video } = this.props;
    this.props.onExplanationContinue(video);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }


  render() {
    const { classes } = this.props;
    const { video } = this.props;
    const { progress } = this.props;
    const { platformUrl } = this.props;
    const { defaultPlatform } = this.props;
    const { courseId } = this.props;
    const { courseUrlName } = this.props;
    const { done } = this.props;
    const { currIdx } = this.props;

      return (
        <div>
          <Paper elevation={2} key="explanation" className={classes.paper}>

            <Typography variant="h5" component="h2" className={classes.title}>
              Explanation
          </Typography>
            <Typography variant="body2" >
              {video.explanation && video.explanation}
            </Typography>

          </Paper>

          <Button variant="contained" color="primary" className={classes.button} onClick={(param) =>
            this.onClickContinue()}>
            Continue
          </Button>

          <Typography variant="body2" className={classes.title}   style={{ marginTop: 40 }}>
              If you want, you can watch the video again:
          </Typography>

          <Player
            video={video}
            progress={progress}
            platformUrl={platformUrl}
            defaultPlatform={defaultPlatform}
            showBottomControls={false}
            currIdx={currIdx}
            courseId={courseId}
            courseUrlName={courseUrlName}
            done={done}
            onVideoDone={null} />
        </div>

      )

  }
}


export default compose(
  withStyles(styles)
)(Explanation);
