import { FormControl, FormControlLabel, Radio, RadioGroup, Card, CardActionArea, CardContent, Paper, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { green, red } from '@material-ui/core/colors';



const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(2),
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  title: {
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    display: "block",
  },
  fieldCorrect:{
    backgroundColor: green[100],
    padding: 8,
  },
  fieldWrong:{
    backgroundColor: red[100],
    padding: 8
  },
  fieldDefault:{
    padding: 8
  }
});


class Question extends Component {
  constructor(props) {
    super(props);
    this.onClickContinue = this.onClickContinue.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      loading: true,
    };

  }

  onClickContinue() {
    const { showAnswer } = this.state;
    if(showAnswer){
      var index = this.state.selected
      var option = this.props.question.options[index]
      if (option) {
        this.setState({ selected: "" });
        this.props.onQuestionSelected(option);
      }
    }else{
      var index = this.state.selected
      var option = this.props.question.options[index]
      if(option && option.next){
        setTimeout(function() {
          this.props.onQuestionSelected(option);
        }.bind(this), 1000)
      }
      this.setState({ showAnswer: true });

    }
    
  }


  onChange(e) {
    this.setState({ selected: e.target.value });
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }



  render() {
    const { classes } = this.props;
    const { question } = this.props;
    const { selected } = this.state;
    const { showAnswer } = this.state;


    console.log("question => ", question);

    return (
      <div >
        <Paper elevation={2} className={classes.paper}>

          <Typography variant="h5" component="h2" className={classes.title}>
            {question.text}
          </Typography>

          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              aria-label="Question"
              name="question"
              className={classes.group}
              onChange={this.onChange}
            >
              {question.options && question.options.map((option, index) => (
                <FormControlLabel 
                  classes={{
                    label: showAnswer ? option.next ? classes.fieldCorrect : selected == (index + "") ? classes.fieldWrong : classes.fieldDefault : classes.fieldDefault,
                  }}
                  disabled={showAnswer}
                  value={index + ""} 
                  control={
                    <Radio 
                      checked={selected == (index + "")} 
                      color="primary" 
                    />
                  } 
                  label={option.text} 
                />
              ))}
            </RadioGroup>
          </FormControl>

        </Paper>

        <Button variant="contained" color="primary" className={classes.button} onClick={(param) =>
            this.onClickContinue()}>
            {showAnswer ? "Continue" : "Check answer"}
          </Button>


      </div>
    );
  }
}


export default compose(
  withStyles(styles)
)(Question);
