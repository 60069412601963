import { Button, Checkbox, CircularProgress, FormControl, FormHelperText, Grid, Link, MenuItem, Paper, TextField, Typography, withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import withBrowserDetection from '../Session/withBrowserDetection';
import { Liability_Waiver } from './../../constants/routes';




const styles = theme => ({
  layout: {
    width: 'auto',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  toolbar: theme.mixins.toolbar
});


const INITIAL_STATE = {
  name: '',
  surname: '',
  email: '',
  password: '',
  password2: '',
  companyId: '',
  tos: false,
  liability: false,
  error: null,
};

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
  componentDidMount() {

  }

  componentWillUnmount() {

  }


  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };
  handleCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };
  handleCheckboxLiability = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onEmailChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }



  onSubmit = event => {
    var { name, surname, email, password } = this.state;

    this.setState({ 
      error: null,
      loading: true 
    });

    email = email.trim().toLowerCase()
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, password)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase.createUser(authUser.user.uid, email, name, surname)
      })
      .then(() => {
        return this.props.firebase.doSendEmailVerification();
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.DASHBOARD);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
        this.setState({ loading: null });

        this.props.firebase.error().add({
          error: JSON.stringify(error),
          createdAt: this.props.firebase.fieldValue.serverTimestamp(),
          msg: error.message,
          errorCode: error.code
        })
      });

    event.preventDefault();
  };


  render() {
    const { classes } = this.props;
    const { state } = this.props;
    const { loading } = this.state;
    const {
      name,
      surname,
      email,
      password,
      password2,
      companyId,
      tos,
      liability,
      error,
    } = this.state;


    const isInvalid =
      password !== password2 ||
      password === '' ||
      email === '' ||
      name === '' ||
      surname === '' ||
      liability === false ||
      tos === false;

    const showNotSamePasswordError = password !== password2 && password2 !== ''
    const isPasswordValid = password.length >= 6 || password == ''

    return (



      <main className={classes.layout}>
        <div className={classes.toolbar} />

        <Typography component="h1" variant="h4" color="textSecondary" align="center">Welcome to Co-instructEd!</Typography>

        <Paper elevation={2} className={classes.paper}>
          <React.Fragment>
            <React.Fragment>

              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <Typography variant="h6" color="textSecondary" >
                    Register your Co-instructEd Account
                  </Typography>
                </Grid>


                <Grid item xs={12} sm={6}>
                  <TextField className={classes.outlined}
                    required
                    id="name"
                    name="name"
                    label="Name"
                    value={name}
                    onChange={this.onChange}
                    fullWidth
                    autoComplete="fname"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="surname"
                    name="surname"
                    label="Surname"
                    value={surname}
                    onChange={this.onChange}
                    fullWidth
                    autoComplete="lname"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="email"
                    name="email"
                    value={email}
                    label="Company email address"
                    onChange={this.onEmailChange}
                    fullWidth
                    autoComplete="email"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    error={!isPasswordValid}
                  >

                    <TextField
                      required
                      error={!isPasswordValid}
                      id="password"
                      name="password"
                      value={password}
                      label="Choose password"
                      onChange={this.onChange}
                      fullWidth
                      autoComplete="password"
                      variant="outlined"
                      type="password"
                    />
                    {isPasswordValid ? null : <FormHelperText id="component-error-text">Please choose a password with at least 6 characters</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    error={showNotSamePasswordError}
                  >
                    <TextField
                      required
                      error={showNotSamePasswordError}
                      id="password2"
                      name="password2"
                      value={password2}
                      onChange={this.onChange}
                      label="Repeat new password"
                      fullWidth
                      autoComplete="password"
                      variant="outlined"
                      type="password"
                    />
                    {showNotSamePasswordError ? <FormHelperText id="component-error-text">Oops! Please retype the password. The passwords must match.</FormHelperText> : null}
                  </FormControl>

                </Grid>


                <Grid item xs={12} >
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Checkbox name="tos" value="tos" color="primary" checked={this.state.tos} onChange={this.handleCheckbox} />
                    <Typography variant="body2">
                      I agree with the <Link target="_blank" rel="noopener" href={ROUTES.TOS}>Privacy Policy</Link>
                    </Typography>

                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Checkbox name="liability" value="liability" color="primary" checked={this.state.liability} onChange={this.handleCheckboxLiability} />
                    <Typography variant="body2">
                      I agree with the <Link target="_blank" rel="noopener" href={ROUTES.LIABILITY_WAIVER}>Liability Waiver</Link>
                    </Typography>

                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.buttons}>
                    {loading ? null : <Button disabled={isInvalid || !isPasswordValid}
                      id="btn-register"
                      variant="contained"
                      color="primary"
                      onClick={this.onSubmit}
                      className={classes.button}
                    >
                      Sign up
                    </Button>}
                    {!loading ? null : <CircularProgress className={classes.button} size={36} style={{ marginRight: 40 }} />}
                  </div>

                </Grid>


                {error && <Grid item xs={12}>
                  <Typography variant="body1" color="error">
                    This email already has an account.<br />
                      Please use the forgot password tool <Link href={ROUTES.PASSWORD_FORGET}>forgot password</Link>
                  </Typography>
                </Grid>}

              </Grid>
            </React.Fragment>
          </React.Fragment>
        </Paper>

        <Paper elevation={2} className={classes.paper}>
          <React.Fragment>
            <React.Fragment>
              <Grid container spacing={2}>

                <Grid item xs={12}>
                  <Typography variant="h6" color="textSecondary"  >
                  You already have an account?
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.buttons}>
                    <Button variant="outlined" color="primary" href={ROUTES.LOGIN}>
                    Sign in here
                    </Button>
                  </div>
                </Grid>

              </Grid>
            </React.Fragment>
          </React.Fragment>
        </Paper>

      </main>

    );
  }
}

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const ERROR_MSG_ACCOUNT_EXISTS = 'This email already has an account. Please use the forgot password tool Sign in';

export default compose(
  withFirebase,
  withStyles(styles),
  withBrowserDetection
)(Register);
