import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress, Grid, ListItemIcon, Button } from '@material-ui/core';
import { withAuthorization } from '../Session';
import DownloadIcon from '@material-ui/icons/CloudDownload';

const styles = theme => ({
  table: {

  },
  head: {
    backgroundColor: theme.palette.secondary.main,
  },
  heading: {
    color: '#fff',
  },
  icon: {
    margin: 2,
    fontSize: 16
  },
  iconCheckbox: {
    margin: 2,
    fontSize: 24
  },
  paper: {

  },
  progress: {
    margin: theme.spacing(6),
  },
});


class CertificateTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
    if (props.userId) {
      this.loadData()
    }
  }



  componentDidMount() {

  }

  componentWillUnmount() {

  }

  generateCertificateDownloadLink = (bucketRef) => {
    const { firebase } = this.props;
    
    // Create a reference with an initial file path and name
    var storage = firebase.storage;
    // Create a reference from a Google Cloud Storage URI
    var gsReference = storage.ref(bucketRef)
  
    gsReference.getDownloadURL().then(function(url) {
      // `url` is the download URL for 'images/stars.jpg'
      console.log("url ", url);
      window.open(url, "_blank")
      
    }).catch(function(error) {
      // Handle any errors
      firebase.addError("generateCertificateDownloadLink error", error)
      console.log("url ", error);
    });
  }


  loadData = () => {
    const { authUser } = this.props;
    const { userId } = this.props;

    console.log("userId ", userId);


    this.props.firebase
      .user(userId)
      .onSnapshot(snapshot => {
        const user = snapshot.data() ? snapshot.data() : null
        if (user) {
          this.setState({ user: user });
        }

      }, function (error) {
        console.log("error = ", error);
      });


    this.props.firebase
      .certificateList()
      .then(items => {
        this.setState({ certificates: items });

      }, function (error) {
        console.log("error = ", error);
      });

  }


  render() {
    const { classes } = this.props;

    const { user } = this.state;
    const { certificates } = this.state;


    
    if (certificates && user) {

      certificates.map((certificate, idx) => {
        const url = (user.certificates && user.certificates[certificate.id] && user.certificates[certificate.id] !== "") ? user.certificates[certificate.id] : null
      
        if(url === null){
          certificate.icon = <LockIcon color="secondary"/>
        }else{
          certificate.icon = <Button size="small" variant="outlined" color="primary" onClick={event => this.generateCertificateDownloadLink(url)} >
                        <DownloadIcon color="primary"/>
                      </Button>
        }
      
      })

    }

    console.log("certificates => ", certificates);
    console.log("user => ", user);


    if (!(certificates && user)) {
      return (
        <Paper elevation={2} className={classes.paper} align="center">
          <CircularProgress className={classes.progress} />
        </Paper>
      )
    }

    return (
      <div>

        <Paper elevation={2} className={classes.paper}>

          <Table className={classes.table}>
            <TableHead className={classes.head}>
              <TableRow>
                <TableCell><Typography className={classes.heading} variant="body2">Certificates</Typography></TableCell>
                <TableCell><Typography className={classes.heading} variant="body2"></Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {certificates && certificates.map((c, idx) => (
                <TableRow key={c.id}
                  // hover={c.isActive}
                >

                <TableCell component="th" scope="row" >
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <img src="/ic_certificate_item.png" height="15" width="20" />
                    <Typography variant="caption" style={{ marginLeft: 10 }}>
                      {c.title}
                    </Typography>
                  </Grid>
                </TableCell>

                <TableCell align="right">
                  {c.icon}
                </TableCell>
                </TableRow>

              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}


const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
  withStyles(styles)
)(CertificateTable);
