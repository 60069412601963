import { FormControl, FormControlLabel, Radio, RadioGroup, Card, CardActionArea, CardContent, Paper, Button, Checkbox, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';



const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  group: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  title: {
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    display: "block",
  },

});


class PlatformInfo extends Component {
  constructor(props) {
    super(props);
    this.onNext = this.onNext.bind(this);

    this.state = {
      loading: true,
    };

  }






  componentDidMount() {

  }

  componentWillUnmount() {

  }


  onNext() {
    this.props.handlePlatformInfoDone();
  }


  render() {
    const { classes } = this.props;



    return (
      <Grid container xs={12} sm={12} md={10} lg={8} spacing={1}>


        <Grid item xs={12} sm={12} md={12}>
          <div >
            <Paper elevation={2} className={classes.paper} >

              <Typography variant="h6" component="h2" className={classes.title}>
              This course is personalized just for you - and only you!
              </Typography>

              <Typography variant="body2" component="h2" className={classes.title}>
              Before you get to a level and video, we will ask you a short question to evaluate if you have mastered 
              this content before. Depending on your answer, we either show you a quick video tutorial or jump right 
              to the next question (and therefore the next level). <br/>
              However, don't worry about missing out: even though you might answer a question right and jump right 
              to the next level, you still get the chance to see the video tutorial and all the other content - we are just 
              trying to make these courses as efficient and effective for you as possible.<br/>
              A final note: don't worry if you get a question wrong - we are all born to learn!<br/>
              Let's start!
          </Typography>

              {/* <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                spacing={5}
                style={{ marginTop: 20 }}
              >
                <Grid item xs container direction="column" alignItems="center" >
                  <img src="/ic_platform_info.png" width={400} />
                </Grid>


              </Grid> */}

            </Paper>

            <Button variant="contained" color="primary" className={classes.button} onClick={(param) =>
            this.onNext()}>
            Continue
          </Button>

          </div>
        </Grid>
      </Grid>

    );

  }


}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
  withStyles(styles)
)(PlatformInfo);
