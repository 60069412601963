export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/reset-password';
export const ADMIN = '/admin';
export const ADMIN_COURSES = '/admin/courses';
export const ADMIN_USERS = '/admin/users';
export const ADMIN_BILLING = '/admin/billing';
export const ADMIN_DEV = '/admin/dev';

export const DASHBOARD = '/dashboard';
export const COURSE = '/course';
export const TOS = '/privacy-policy';
export const LIABILITY_WAIVER = '/liability-waiver';
export const REGISTER = '/register';
export const LOGIN = '/login';
export const LOGIN_EMAIL = '/login-email';
export const FAQ = '/faq';
export const SETTINGS = '/settings';

